<template>
  <div class="pdf">
    <iframe
      :src="`/static/pdf/web/viewer.html?file=${encodeURIComponent(src)}`"
      frameborder="0"
      style="width: 100%; height: calc(100vh)"
    ></iframe>
  </div>
</template>

<script>
import { getDetail } from "@/api/home";
import { getPdf } from "@/api/home.js";
import baseUrl from "@/api/baseurl.js";
import { getToken } from "../../utils/auth";
export default {
  data() {
    return {
      baseUrl: baseUrl.baseUrl,
      src: null,
      loading: false,
      form: {
        dataId: "",
        archiveSid: "",
      },
    };
  },
  activated() {
    // this.getPdfCode();
  },
  created() {
    const query = this.$route.query;
    if (query.dataId) {
      this.form.dataId = query.dataId;
    }
    if (query.archiveSid) {
      this.form.archiveSid = query.archiveSid;
    }

    getDetail(this.form.dataId)
      .then(res => {
        if (res.data.state == "OUT_SHELVE") {
          this.$message.warning("该图书已下架，无法访问");
          this.$router.push("/index");
          return;
        }
      })
      .catch(error => {
        this.$router.push("/index");
      });
  },
  methods: {
    getPdfCode: function () {
      this.loading = true;
      this.prohibit();
      let token = getToken();
      // 数据文件流 转成 pdf
      this.src = `${this.baseUrl}/api/v1/business/data/load?dataId=${this.form.dataId}&archiveSid=${this.form.archiveSid}&token=${token}&flag=1`;
    },
    // 禁用鼠标右击、F12 来禁止打印和打开调试工具
    prohibit() {
      document.oncontextmenu = function (ev) {
        return false; //屏蔽右键菜单
      };
      document.onkeydown = function (e) {
        if (
          e.ctrlKey &&
          (e.keyCode === 65 ||
            e.keyCode === 67 ||
            e.keyCode === 73 ||
            e.keyCode === 74 ||
            e.keyCode === 80 ||
            e.keyCode === 83 ||
            e.keyCode === 85 ||
            e.keyCode === 86 ||
            e.keyCode === 117)
        ) {
          return false;
        }
        if (e.keyCode === 18 || e.keyCode === 123) {
          return false;
        }
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPdfCode();
    });
  },
};
</script>

<style lang="scss" scoped></style>
